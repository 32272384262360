import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import { config } from './config';
import { main } from './configuration';
import { store } from './configuration/setup/store';
import { handleLoginRedirect } from './configuration/login/redirect';
import { ErrorBoundary } from './features/app/ErrorBoundary';
import App from './features/app/App';
import { User } from 'oidc-client-ts';
import { InvalidLogin } from './features/app/InvalidLogin';

const renderApplication = ({ user }: { user: User | null }) => {
    const container = document.getElementById('root');
    const root = createRoot(container!);

    let showApp = true;
    try {
        showApp = user!.profile.sub.startsWith('azure-my-rio-de:');
    } catch (e) {
        console.error(e);
    }

    if (showApp) {
        root.render(
            <ErrorBoundary>
                <Provider store={store}>
                    <HashRouter>
                        <App />
                    </HashRouter>
                </Provider>
            </ErrorBoundary>
        );
    } else {
        root.render(<InvalidLogin idToken={user?.id_token} />);
    }
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else {
    main(renderApplication);
}
