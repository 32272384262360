import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import { DEFAULT_ROUTE, IOT_EVENTS_ROUTE } from '../routes/routes.ts';
import { useAppSelector } from '../../../configuration/setup/hooks.ts';
import { getIdToken } from '../../../configuration/tokenHandling/tokenSlice.ts';
import { config } from '../../../config.ts';

const AppHeader = () => {
    // const idToken = useAppSelector(getIdToken);
    const navItems = [
        {
            key: 'accounts',
            route: (
                <a href={'https://helpgang.iam.rio.cloud/'}>
                    <FormattedMessage id={'assets-helpgang.sublink.accounts'} />
                </a>
            ),
        },
        {
            key: 'partner-integrations',
            route: (
                <a href={'https://helpgang.iam.rio.cloud/#/partner-integrations'}>
                    <FormattedMessage id={'assets-helpgang.sublink.partner-integrations'} />
                </a>
            ),
        },
        {
            key: 'client-metrics',
            route: (
                <a href={'https://helpgang.iam.rio.cloud/#/client-metrics'}>
                    <FormattedMessage id={'assets-helpgang.sublink.client-metrics'} />
                </a>
            ),
        },
        {
            key: 'assets',
            route: (
                <NavLink to={DEFAULT_ROUTE}>
                    <FormattedMessage id={'assets-helpgang.application-header.assets'} />
                </NavLink>
            ),
        },
        {
            key: 'device-state',
            route: (
                <a href={'https://corecon-admin-web.vcp-connector.rio.cloud/#/device-state/home'} target={'_blank'}>
                    <FormattedMessage id={'assets-helpgang.application-header.device-state'} />
                </a>
            ),
        },
        {
            key: 'inbound-connections',
            route: (
                <a href={'https://helpgang.iam.rio.cloud/#/inbound-connections'}>
                    <FormattedMessage id={'assets-helpgang.sublink.inbound-connections'} />
                </a>
            ),
        },
        {
            key: 'activation',
            route: (
                <a href={'https://admin.marketplace.rio.cloud/activation'}>
                    <FormattedMessage id={'assets-helpgang.sublink.activation'} />
                </a>
            ),
        },
        {
            key: 'discount',
            route: (
                <a href={'https://admin.marketplace.rio.cloud/discount'}>
                    <FormattedMessage id={'assets-helpgang.sublink.discount'} />
                </a>
            ),
        },
        {
            key: 'iot-events',
            route: (
                <NavLink to={IOT_EVENTS_ROUTE}>
                    <FormattedMessage id={'assets-helpgang.sublink.iot-events'} />
                </NavLink>
            ),
        },
    ];

    const appTitle = <FormattedMessage id={'assets-helpgang.application-header.moduleName'} />;
    const idToken = useAppSelector(getIdToken)!;
    const postLogout = config.postLogoutRedirectUri
        ? {
              redirectUri: config.postLogoutRedirectUri,
              idTokenHint: idToken,
          }
        : undefined;

    const userMenu = <DefaultUserMenu environment={import.meta.env.MODE} disableCookieModal postLogout={postLogout} />;

    return <ApplicationHeader label={appTitle} navItems={navItems} actionBarItems={[userMenu]} />;
};

export default AppHeader;
