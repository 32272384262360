import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import { PropsWithChildren } from 'react';
import { config } from '../../config.ts';
import { urlEncodeIfNecessary } from '../../utils.ts';

export const InvalidLogin = ({ idToken }: { idToken: string | undefined }) => {
    const logoutURL = `${config.logoutUri}?post_logout_redirect_uri=${urlEncodeIfNecessary(config.postLogoutRedirectUri!)}&id_token_hint=${urlEncodeIfNecessary(idToken!)}`;
    return (
        <InvalidLoginWrapper>
            <div className={'text-size-h2 text-center margin-bottom-10 text-color-dark'}>Login Error</div>
            <div className={'margin-left-20 margin-right-20 margin-bottom-25 text-center text-color-darker'}>
                You&apos;re logged in with a RIO platform account. Either log out and visit this page again, or proceed
                to
                <br />
                <a href={'https://home.rio.cloud'}>home.rio.cloud</a>.
            </div>
            <div className={'display-flex flex-row justify-content-around'}>
                <a href={logoutURL} className={'btn btn-default'}>
                    Logout
                </a>
                <a href={'https://home.rio.cloud'} className={'btn btn-primary'}>
                    home.rio.cloud
                </a>
            </div>
        </InvalidLoginWrapper>
    );
};

const InvalidLoginWrapper = (props: PropsWithChildren) => (
    <ApplicationLayout>
        <ApplicationLayout.Body>
            <div className={'display-flex justify-content-center height-100pct'}>
                <div className={'width-100pct max-width-400 align-self-center'}>
                    <div className={'panel-default panel panel-body padding-25 flex-column'}>{props.children}</div>
                </div>
            </div>
        </ApplicationLayout.Body>
    </ApplicationLayout>
);
