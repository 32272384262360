export const sleep = (sleepTimeMs: number) => new Promise((f) => setTimeout(f, sleepTimeMs));

export const getSingleOrThrow = <T>(items: T[], errorMessage: (itemsCount: number) => string): T => {
    if (items.length === 1 && items[0] !== undefined) {
        return items[0];
    } else {
        throw new Error(errorMessage(items.length));
    }
};
export const urlEncodeIfNecessary = (url: string) => {
    try {
        return decodeURIComponent(url) === url ? encodeURIComponent(url) : url;
    } catch {
        return encodeURIComponent(url);
    }
};
